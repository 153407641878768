import React from 'react'
import { useAppDispatch } from '../hooks/reduxToolkit'
import { useAsyncAction } from '../hooks/useAsyncAction'
import { useClient, useSession } from '../providers/supabase'
import { persistor } from '../reduxToolkit/store';

export function SignOutButton({ className, children }: React.PropsWithChildren<{ className?: string }>) {
  const session = useSession()
  const client = useClient()
  const dispatch = useAppDispatch()

  const [signOutStatus, doSignOut] = useAsyncAction(async (evt: React.MouseEvent) => {
    evt.preventDefault();

    const resp = await client.auth.signOut()
    if (resp.error) { throw resp.error }

    await persistor.purge()
    setTimeout(() => {
      // Clear out any hash params
      const url = new URL(window.location.href)
      url.pathname = '/'
      url.hash = ''
      window.history.pushState({}, '', url)
      window.location.reload()
    }, 100)
  }, [client, dispatch])

  if (!session) {
    return null
  }

  if (signOutStatus.error) {
    throw signOutStatus.error
  }

  return <a className={`${className || 'btn btn-link'}`} href="/"
      onClick={doSignOut}>
    {children}
  </a>

}
