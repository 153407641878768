import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {v4 as uuid4} from 'uuid'
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxToolkit";
import { RequireSubmissionProvided, requireSubmission } from "../../../wrappers/requireSubmission";
import { generateDocuments2023 } from "../../../../lib/documents/2023";
import { useClient } from "../../../providers/supabase";
import { SubmissionById } from "../../../reduxToolkit/selectors/submissionById";
import { useAsyncAction } from "../../../hooks/useAsyncAction";
import { useLoadBlobs } from "../../../hooks/useLoadBlobs";
import { documentPack } from "../../../../lib/documents/2023";
import { BlobFileBrowser } from "../../../components/blobFileBrowser";
import { createBlob } from "../../../../lib/supabase/createBlob";
import { createAttachment, deleteAttachment } from "../../../reduxToolkit/attachmentsSlice";
import { Checklist } from "../../../components/checklist";
import { formatDateInTimeZone } from "../../../../lib/formatDateInTimeZone";
import { slugify } from "../../../../lib/util/slugify";
import { useUser } from "../../../hooks/useUser";
import { completeSubmission } from "../../../reduxToolkit/actions/completeSubmission";
import { ExpenseModel, isCompleteExpense } from "../../../reduxToolkit/expensesSlice";
import { BlobRow } from "../../../../types/supabase";
import { SubmissionDataV1CHM, isSubmissionDataV1, isSubmitted, updateSubmission } from "../../../reduxToolkit/submissionsSlice";
import { present } from "../../../../lib/util/present";
import { useNavigate } from "react-router";
import { usePendingExpenses } from "../../../hooks/usePendingExpenses";
import { SignatureCanvas } from "../../../components/signatureCanvas";
import { setProfile } from "../../../reduxToolkit/membershipSlice";
import { isEqual, merge } from "lodash";
import { assert } from "../../../../lib/util/assert";
import { postProcessSignatureData } from "../../../components/forms/profileForm";
import { useFeature } from "../../../providers/featureFlags";
import { EmailMethodTab } from "./submitToCHM/emailMethodTab";

import './submitToChm.scss'
import { isMobile } from "../../../../lib/util/isMobile";

type SubmitToChmFlowState =
  'fillRemainingData' |
  'generateDocuments' |
  'nextSteps' |
  'done'

function raiseUnknownFlowState(state: never): never {
  throw new Error(`Unknown flow state ${state}`)
}


interface SubmitToCHMProps extends RequireSubmissionProvided {
}

export function SubmitToCHM({ submission }: SubmitToCHMProps) {
  const navigate = useNavigate()

  const pendingExpenses = usePendingExpenses(submission).filter(isCompleteExpense)

  const allAttachmentRecords = [submission.id, submission.incident_id, ...(submission.expense_ids || []), ...pendingExpenses.map((e) => e.id)]
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => allAttachmentRecords.includes(a.record_id)))

  const [blobs, loadBlobsState] = useLoadBlobs(attachments.map((a) => a.blob_key))

  const [flowState, _setFlowState] = useState<SubmitToChmFlowState>('fillRemainingData')
  const setFlowState = useCallback((state: SubmitToChmFlowState) => {
    _setFlowState(state)
    if (state === 'done') {
      navigate(`/incidents/${submission.incident_id}`)
    }
  }, [navigate, submission.incident_id])

  if (loadBlobsState.error) { throw loadBlobsState.error }

  if (flowState === 'fillRemainingData') {
    return <FillRemainingData submission={submission} blobs={blobs} setFlowState={setFlowState} />
  }

  if (flowState === 'generateDocuments') {
    return <GenerateDocumentsStep submission={submission} blobs={blobs} setFlowState={setFlowState} />
  }

  if (loadBlobsState.loading) {
    return <div className="row submit-to-chm">
      <div className="col-12">
        <h2>Submit to CHM</h2>

        <>
          <p>Loading your PDFs...</p>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      </div>
    </div>
  }
  assert(blobs, 'blobs should be loaded at this point')

  if (flowState === 'nextSteps') {
    return <NextStepsChecklist submission={submission} pendingExpenses={pendingExpenses} blobs={blobs} setFlowState={setFlowState} />
  }
  
  if (flowState === 'done') {
    return <div className="row submit-to-chm">
      <div className="col-12">
        <h2>Submit to CHM</h2>

          <p>All Done!</p>
          <button className="btn btn-primary" onClick={() => navigate(`/incidents/${submission.incident_id}`)}>
            Back to Incident
          </button>
      </div> 
    </div>
  }

  raiseUnknownFlowState(flowState)
}

export default requireSubmission(SubmitToCHM)

interface GenerateDocumentsStepProps extends RequireSubmissionProvided {
  blobs?: BlobRow[]
  setFlowState: (state: SubmitToChmFlowState) => void
}

function GenerateDocumentsStep({ submission, blobs, setFlowState }: GenerateDocumentsStepProps) {
  const [generateDocumentsState, generateDocuments] = useGenerateDocuments(submission)
  const documentsAreGenerated = documentPack.every((documentName) => blobs?.find((b) => b.file_name === documentName))
  
  // After we load the blobs, if we don't have CHM docs yet, generate and upload them.
  useEffect(() => {
      if (documentsAreGenerated) {
        // We already have all the needed documents
        setFlowState('nextSteps')
        return
      }

      generateDocuments()
        .then(() => {
          setFlowState('nextSteps')
        })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateDocuments, documentsAreGenerated])

  if (generateDocumentsState.error) { throw generateDocumentsState.error }
  
  return <div className="row submit-to-chm">
    <div className="col-12">
      <h2>Submit to CHM</h2>

      <>
        <p>Generating your PDFs...</p>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </>
    </div>
  </div>
  
}

function useGenerateDocuments(
  submission: SubmissionById
) {
  const client = useClient()
  const dispatch = useAppDispatch()

  const membership = useAppSelector((s) => s.membership)
  const pendingExpenses = useAppSelector((s) => s.expenses.expenses.filter((e) => {
    // Expenses for another incident - not included in this CHM submission
    if (e.incident_id !== submission.incident_id) { return false }

    // Unsubmitted expense - included in this CHM submission
    if (!e.submission_id) { return true }
    // Expenses that were last submitted to an HRA - rolled up into this CHM submission
    const lastSubmissionForThisExpense = s.submissions.submissions.find((s) => s.id === e.submission_id)
    if (lastSubmissionForThisExpense?.submission_type === 'HRA') { return true }

    return false
  }))

  const dependent = useAppSelector((s) => s.dependents.lastSync?.dependents.find((d) =>
    d.full_name == submission.incident.patient_name && d.date_of_birth == submission.incident.patient_dob))

  return useAsyncAction(async () => {
    const user = (await client.auth.getUser()).data.user
    if (!user) { throw new Error('Not signed in') }
    if (!membership.profile) { throw new Error('Profile not set') }

    const extraData: SubmissionDataV1CHM | undefined =
      isSubmissionDataV1(submission.data) && submission.data.type == 'CHM' ?
        submission.data :
        undefined

    const documents = await generateDocuments2023({
      user,
      membership,
      dependent: dependent || null,
      profile: membership.profile,
      incident: submission.incident,
      expenses: pendingExpenses
    }, {
      ...extraData,
    })

    const blobs = await Promise.all(documents.map(async (file) => {
      return await createBlob(file,
        {
          membership_id: membership.membershipId,
        },
        {
          client
        })
    }))

    const attachments = blobs.map((blob) => {
      return dispatch(createAttachment({
        id: uuid4(),
        record_id: submission.id,
        table_name: 'submissions',
        blob_key: blob.key,
        membership_id: blob.membership_id,
        updated_at: blob.updated_at,
        created_at: blob.created_at,
      })).payload
    })

    return {
      documents,
      blobs,
      attachments
    }
  }, [submission, membership, pendingExpenses, dependent, client, dispatch])
}

interface FillRemainingDataProps extends RequireSubmissionProvided {
  blobs?: BlobRow[]
  setFlowState: (state: SubmitToChmFlowState) => void
}

function FillRemainingData({submission, blobs, setFlowState}: FillRemainingDataProps) {
  const dispatch = useAppDispatch()
  const client = useClient()

  const blobKeys = blobs?.map((b) => b.key) || []
  const attachments = useAppSelector((s) => s.attachments.attachments.filter(
    (a) => a.record_id === submission.id &&
      blobKeys.includes(a.blob_key)))

  const profile = useAppSelector((s) => s.membership.profile)
  const initialSignature = useMemo(() => profile?.signature_data, [])
  
  const [submitting, setSubmitting] = useState(false)

  const [data, setData] = useState<SubmissionDataV1CHM>(
    isSubmissionDataV1(submission.data) && submission.data.type == 'CHM' ?
      submission.data :
      {
        _version: '2023-05-04',
        type: 'CHM',
        sharingRequestFormData: {
          previousConditions: false,
          isAccident: false,
          financialAssistance: false,
        }
      })

  useEffect(() => {
    // We reloaded the page and we've already done this
    if (isSubmitted(submission)) {
      console.log('submission is already submitted')
      setFlowState('done')
    }
  }, [submission, setFlowState])

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!(e.target as HTMLFormElement).checkValidity()) { return }

    // update the submission
    const now = new Date().toISOString()
    dispatch(updateSubmission({
      id: submission.id,
      updated_at: now,
      data,
    }))
    // now we need to re-generate the documents
    attachments.forEach((a) => {
      dispatch(deleteAttachment({
        id: a.id,
        updated_at: now,
        deleted_at: now,
      }))
    })
    setSubmitting(true)
    
    // We need to wait for old documents to be deleted before we can generate new ones
    setTimeout(() => {
      setFlowState('generateDocuments')
      setSubmitting(false)
    }, 400)
  }

  const [_, updateSignature] = useAsyncAction(async (signature: string) => {
    const updatedProfile = {
      ...profile,
      id: profile?.id || uuid4(),
      updated_at: new Date().toISOString(),
      signature_data: present(signature) ? await postProcessSignatureData(signature) : null,
    }
    const result = await client.from('profiles')
      .upsert(updatedProfile)
      .select('*')
      .single()

    if (result.error) { throw result.error }

    dispatch(setProfile({
      profile: merge({}, updatedProfile, result.data)
    }))
  }, [client])

  const acidentLocation = typeof(data?.sharingRequestFormData?.accidentOccurredAt) == 'string' ?
    data?.sharingRequestFormData?.accidentOccurredAt :
    data?.letterOfExplanationData?.where

  const whatHappened = typeof(data?.letterOfExplanationData?.what) == 'string' ?
    data?.letterOfExplanationData?.what :
    submission.incident.description

  return <div className="row submit-to-chm">
    <div className="col-12">
      <h2>Submit to CHM</h2>

      <p>
        We've filled out as much as we can, but there's still a little left for you to fill out.
      </p>

      <form onSubmit={submit}>
        <div className="accordion" id="fill-remaining-data-form">
          <div className="accordion-item">
            <h4 className="accordion-header">
              <button className="accordion-button" type="button"
                  data-bs-toggle="collapse" data-bs-target="#letter-of-explanation-form"
                  aria-expanded="true" aria-controls="letter-of-explanation-form">
                Letter of Explanation
              </button>
            </h4>

            <div className="accordion-collapse collapse show" id="letter-of-explanation-form" data-bs-parent="#fill-remaining-data-form">
              <label className="form-label">What Happened?</label>
              <textarea className='form-control'
                rows={3}
                required
                value={whatHappened || ''}
                onChange={(e) => setData({
                  ...data,
                  letterOfExplanationData: {
                    ...data.letterOfExplanationData,
                    what: e.target.value
                  }
                })
              } />

              <label className="form-label">Where did it happen? (optional)</label>
              <textarea className='form-control'
                rows={3}
                value={data.letterOfExplanationData?.where || ''}
                onChange={(e) => setData({
                  ...data,
                  letterOfExplanationData: {
                    ...data.letterOfExplanationData,
                    where: e.target.value
                  }
                })
              } />

              <label className="form-label">Anything Else to Add? (optional)</label>
              <textarea className='form-control'
                rows={3}
                value={data.letterOfExplanationData?.additionalInfo || ''}
                onChange={(e) => setData({
                  ...data,
                  letterOfExplanationData: {
                    ...data.letterOfExplanationData,
                    additionalInfo: e.target.value
                  }
                })
              } />
            </div>
          </div>

          <div className="accordion-item">
            <h4 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sharing-request-form" aria-expanded="true" aria-controls="sharing-request-form">
                Sharing Request Form
              </button>
            </h4>

            <div className="accordion-collapse collapse show" id="sharing-request-form" data-bs-parent="#fill-remaining-data-form">
              <div className='input-group'>
                <span className='input-group-text'>Physician's Diagnosis</span>
                <input type='text' className='form-control'
                  value={data.sharingRequestFormData?.physiciansDiagnosis || ''}
                  placeholder='Broken Arm / COVID-19 / etc.'
                  onChange={(e) =>  setData({
                    ...data,
                    sharingRequestFormData: {
                      ...data.sharingRequestFormData,
                      physiciansDiagnosis: e.target.value
                    }
                  })}/>
              </div>
              <div className="input-group has-validation">
                <span className='input-group-text'>Date Symptoms Began</span>
                <input name="datepic" placeholder="DateRange" type="date"
                  className={`form-control`}
                  required
                  value={data.sharingRequestFormData?.dateSymptomsBegan || submission.incident.start_date}
                  onChange={(e) =>  setData({
                    ...data,
                    sharingRequestFormData: {
                      ...data.sharingRequestFormData,
                      dateSymptomsBegan: e.target.value
                    }
                  })}/>
              </div>

              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="previousConditions"
                  checked={data.sharingRequestFormData?.previousConditions || false}
                  onChange={(e) => setData({
                    ...data,
                    sharingRequestFormData: {
                      ...data.sharingRequestFormData,
                      previousConditions: e.target.checked
                    }
                  })}/>
                <label className="form-check-label" htmlFor="previousConditions">
                  Did you have signs, symptoms, testing, or treatment of this condition before joining CHM?
                </label>
              </div>

              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="isAccident"
                  checked={data.sharingRequestFormData?.isAccident || false}
                  onChange={(e) => setData({
                    ...data,
                    sharingRequestFormData: {
                      ...data.sharingRequestFormData,
                      isAccident: e.target.checked,
                      accidentOccurredAt: e.target.checked ? acidentLocation : undefined
                    }
                  })}/>
                <label className="form-check-label" htmlFor="previousConditions">
                  Was this an accident?
                </label>
              </div>

              {data.sharingRequestFormData?.isAccident &&
                <div className='input-group'>
                  <span className='input-group-text'>Where?</span>
                  <input type='text' className='form-control'
                    required={data.sharingRequestFormData?.isAccident}
                    value={typeof(acidentLocation) == 'string' ? acidentLocation : 'home'}
                    list='accident-locations'
                    onChange={(e) =>  setData({
                      ...data,
                      sharingRequestFormData: {
                        ...data.sharingRequestFormData,
                        accidentOccurredAt: e.target.value
                      }
                    })}/>

                  <datalist id="accident-locations">
                    <option value="home" />
                  </datalist>
                </div>}

              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="isAccident"
                  checked={data.sharingRequestFormData?.financialAssistance || false}
                  onChange={(e) => setData({
                    ...data,
                    sharingRequestFormData: {
                      ...data.sharingRequestFormData,
                      financialAssistance: e.target.checked
                    }
                  })}/>
                <label className="form-check-label" htmlFor="previousConditions">
                  I have applied or am in the process of applying for financial assistance in accordance with the CHM Guidelines.
                </label>
              </div>

            </div>
          </div>
        </div>

        {!initialSignature &&
            <div className="mt-3">
              <h4 >
                Digital Signature
              </h4>

              <SignatureCanvas
                data={profile?.signature_data}
                onChange={(signatureData) => {
                  if (signatureData) {
                    updateSignature(signatureData)
                  }
                }}/>
            </div>}

        <div className="col-12 mt-3 d-flex">
          <span className="me-auto">&nbsp;</span>
            <button type='submit' className={`btn btn-primary ${submitting && 'disabled'}`}>
              Submit
            </button>
        </div>
      </form>
    </div>
  </div>
}

interface NextStepsChecklistProps extends RequireSubmissionProvided {
  pendingExpenses: ExpenseModel[]
  blobs: BlobRow[]

  setFlowState: (state: SubmitToChmFlowState) => void
}

function NextStepsChecklist({ submission, pendingExpenses, blobs, setFlowState }: NextStepsChecklistProps) {

  const dispatch = useAppDispatch()
  const user = useUser()

  const finishSubmission = () => {
    dispatch(completeSubmission({
      submission: submission,
      blobs: blobs || [],
      pendingExpenses: pendingExpenses,
      submittedByUserId: user?.id || '',
    }))
      .finally(() => {
        setFlowState('done')
      })
  }

  useEffect(() => {
    // We reloaded the page and we've already done this
    if (isSubmitted(submission)) {
      console.log('submission is already submitted')
      setFlowState('done')
    }
  }, [submission, setFlowState])

  const zipFileName = `${formatDateInTimeZone(submission.incident.start_date, { format: 'yyyy-MM-dd' })}_${slugify(submission.incident.description || '')}`
  
  const emailTabShown = useFeature('share_chm_submission')
  const mobile = isMobile()
  const canShare = typeof navigator.share === 'function'
  const emailTabShownFirst = emailTabShown && mobile && canShare
  
  return <div className="row submit-to-chm">
    <div className="col-12">
      <h2>Submit to CHM</h2>
    </div>
    <div className="col-12 col-lg-6 submit-to-chm__file-browser">
      <BlobFileBrowser
        settings={{
          hideNavigation: true,
          initialView: 'list',
        }}
        rootFolderName={zipFileName}
        files={{
          '/': blobs
          }} />
    </div>
    <div className="col-12 col-lg-6 submit-to-chm__next-steps">
      <ul className="nav nav-tabs" id="myTab" role="tablist" style={!emailTabShown ? { display: 'none' } : {}}>
        <li className="nav-item" role="presentation">
          <button id="chm-portal-tab"
            className={`nav-link ${!emailTabShownFirst && 'active'}`}
            data-bs-toggle="tab" data-bs-target="#chm-portal-tab-pane" type="button" role="tab"
            aria-controls="home-tab-pane" aria-selected="true">
              Browser Method
            </button>
        </li>
        {emailTabShown && <li className="nav-item" role="presentation">
                
          <button id="email-tab"
            className={`nav-link ${emailTabShownFirst && 'active'}`}
            data-bs-toggle={'tab'} data-bs-target="#email-tab-pane" type="button" role="tab"
            aria-controls="profile-tab-pane" aria-selected="false">
              Email Method
          </button>
        </li>}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className={`tab-pane ${!emailTabShownFirst && 'show active'}`} id="chm-portal-tab-pane" role="tabpanel" aria-labelledby="chm-portal-tab" tabIndex={0}>
          <h4>Next Steps:</h4>
          <p>
            <Checklist
              items={[
                <span>
                  Download all the files to your computer.<br/>
                  <small><i>You can download a zip file in the "Actions" menu</i></small>
                </span>,
                <span>
                  Log In to the Membership portal, find the "Sharing" drop-down, and click "Submit Medical Bills" <br/>
                  <a href="https://portal.chministries.org/Needs/SubmitNeed" target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
                    https://portal.chministries.org/Needs/SubmitNeed</a>
                </span>,
                <span>
                  Click on "Upload Printed PDFs"
                </span>,
                <span>
                  For each PDF, click "Browse" and select the file from your "Downloads" folder.
                </span>,
                <span>
                  For each itemized receipt in your files, click "Itemized Bill" and browse to the correct file.
                </span>
              ]} />
          </p>
        </div>
        {emailTabShown && <div className={`tab-pane ${emailTabShownFirst && 'show active'}`} id="email-tab-pane" role="tabpanel" aria-labelledby="email-tab" tabIndex={1}>
          <EmailMethodTab incident={submission.incident} blobs={blobs} zipFileName={zipFileName} />
        </div>}
      </div>
    </div>

    <div className="col-12">
      <p>
        <button onClick={finishSubmission}
            className={`btn w-100 btn-primary`}>
          I've submitted my documents
        </button>
      </p>
    </div>
  </div>
}

