import { DependentsForm } from "../../components/forms/dependentsForm";
import { RequireAuthProvided } from "../../wrappers/requireAuth";

export interface OnboardingAddDependentsProps extends RequireAuthProvided {
  onComplete?: () => Promise<void> | void
}


export function OnboardingAddDependents({ onComplete }: OnboardingAddDependentsProps) {
  return <div className="row mt-4">
  <div className="col-12">
    <h1>Add dependents</h1>
    <p>
      If you have any dependents on your plan, you can add them here to track
      expenses for them.
    </p>

    <div className="row">
      <div className="col-12">
        <DependentsForm />
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <button className="btn btn-link" onClick={onComplete}>
          I'm done adding dependents
          <i className="material-icons">next</i>
        </button>
      </div>
    </div>
  </div>
</div>
}
