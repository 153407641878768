import { User } from "@supabase/supabase-js"
import { IncidentModel } from "../../app/reduxToolkit/incidentsSlice"
import { formatDateInTimeZone } from "../formatDateInTimeZone"
import {fillPdf} from './fillPdf'
import { writeSignature } from "./writeSignature"
import { differenceInMonths, differenceInYears } from "date-fns"
import { ProfileModel } from "../../app/reduxToolkit/membershipSlice"

const sharingRequestFormFields = [
  'Text2',        'Text3',
  'Text4',        'Text5',
  'Text6',        'Home',
  'Work',         'Cell',
  'Text1',        'YY',
  'Age',          'MM',
  'DD',           'SymD',
  'SymY',         'SymM',
  'DDM',          'DDD',
  'DDY',          'DOBM',
  'DOBD',         'DOBY',
  "Child's Name", 'Patient Name',
  'Name',         'Email Address',
  'Conditions',   'Accidents',
  'Diagnosis',    'Accident Location',
  'Primary Pay',  'Finance Assist',
  'signature',    'Month',
  'Day',          'Year'
] as const

type SharingRequestFormField = typeof sharingRequestFormFields[number]

const phoneTypeToFormField: Record<string, SharingRequestFormField> = {
  'home': 'Home',
  'work': 'Work',
  'cell': 'Cell'
}

export interface SharingRequestFormExtraData {
  physiciansDiagnosis?: string
  dateSymptomsBegan?: string

  previousConditions?: boolean

  isAccident?: boolean
  accidentOccurredAt?: string

  primaryPaymentSources?: boolean
  financialAssistance?: boolean
}

export async function fillSharingRequestForm(
  pdfBytes: ArrayBuffer,
  {user, membership, profile, incident}: {
    user: Pick<User, 'email'>,
    membership: { member_number?: string | null },
    profile: ProfileModel | null | undefined,
    incident: IncidentModel
  },
  extraData: SharingRequestFormExtraData | null | undefined
): Promise<ArrayBuffer> {

  const fields: { [k in SharingRequestFormField]?: string | null | undefined } = {
    Name: profile?.full_name,
    "Email Address": user.email,
    "Patient Name": incident?.patient_name,
  }

  if (membership?.member_number) {
    for (let i = 0; i < 6; i++) {
      fields[`Text${i + 1}` as SharingRequestFormField] = membership.member_number.charAt(i)
    }
  }

  if (profile?.phone) {
    const fieldName = phoneTypeToFormField[profile.phone_type || 'cell']
    fields[fieldName] = profile.phone
  }

  if (incident?.patient_dob) {
    const dob = new Date(Date.parse(formatDateInTimeZone(incident.patient_dob)))
    fields['DD'] = dob.getDate().toString()
    fields['MM'] = (dob.getMonth() + 1).toString()
    fields['YY'] = dob.getFullYear().toString().slice(2)

    const age = differenceInYears(new Date(), dob)
    if (age < 1) {
      const diff = differenceInMonths(new Date(), dob).toString() + 'm'
      if (diff.length >= 3) {
        fields['Age'] = '1'
      } else {
        fields['Age'] = diff
      }
    } else {
      fields['Age'] = age.toString()
    }
  }

  const diagnosis = extraData?.physiciansDiagnosis || incident.description
  if (diagnosis) {
    fields["Diagnosis"] = diagnosis
  }

  const dateSymptomsBegan = extraData?.dateSymptomsBegan || incident.start_date
  if (dateSymptomsBegan) {
    const dt = new Date(Date.parse(formatDateInTimeZone(dateSymptomsBegan)))
    fields['SymD'] = dt.getDate().toString()
    fields['SymM'] = (dt.getMonth() + 1).toString()
    fields['SymY'] = dt.getFullYear().toString().slice(2)
  }

  if (extraData?.previousConditions) {
    fields['Conditions'] = 'Yes'
  }

  if (extraData?.accidentOccurredAt?.trim().toLocaleLowerCase() == 'home') {
    fields['Accidents'] = 'Yes'
  } else if (extraData?.accidentOccurredAt) {
    fields['Accidents'] = 'No'
    fields['Accident Location'] = extraData.accidentOccurredAt
  }

  if (extraData?.primaryPaymentSources) {
    fields['Primary Pay'] = 'Yes'
  }
  if (extraData?.financialAssistance) {
    fields['Finance Assist'] = 'Yes'
  }

  fields['Month'] = ((new Date()).getMonth() + 1).toString()
  fields['Day'] = (new Date()).getDate().toString()
  fields['Year'] = (new Date()).getFullYear().toString().slice(2)

  pdfBytes = await fillPdf(pdfBytes, fields)

  if (profile?.signature_data) {
    pdfBytes = await writeSignature(pdfBytes, {
      signature: profile.signature_data,
      formField: 'signature'
    })
  }

  return pdfBytes
}
