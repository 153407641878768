import { merge } from "lodash";
import { FormFilledProfile, ProfileForm } from "../../components/forms/profileForm";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit";
import { useAsyncAction } from "../../hooks/useAsyncAction";
import { useLoadProfileFromServer } from "../../hooks/useLoadProfileFromServer";
import { useClient } from "../../providers/supabase";
import { ProfileModel, setProfile } from "../../reduxToolkit/membershipSlice";
import { RequireAuthProvided } from "../../wrappers/requireAuth";

export interface OnboardingFillProfileProps extends RequireAuthProvided {
  onProfileFilled?: () => Promise<void> | void
}

export function OnboardingFillProfile({user, onProfileFilled}: OnboardingFillProfileProps) {
  const dispatch = useAppDispatch()
  const client = useClient()
  const profile = useAppSelector((s) => s.membership.profile)

  useLoadProfileFromServer()

  const [updateProfileState, updateProfile] = useAsyncAction(async (unsavedProfile: FormFilledProfile) => {
    // Ensure the profile has your userId
    const profile = {
      ...unsavedProfile,
      id: user.id,
      updated_at: new Date().toISOString()
    }
    const response = await client.from('profiles')
      .upsert(profile)
      .select('*')
      .single()

    if (response.error) {
      throw response.error
    }

    dispatch(setProfile({
      profile: merge({}, profile, response.data)
    }))

    return response
  }, [client, user.id])

  return <div className="row mt-4">
    <div className="col-12 col-xl-8 offset-xl-2">
      <h1>Fill Your Profile</h1>
      <p>
        We only use this information to fill out reimbursement forms on your behalf.
      </p>
      {updateProfileState.error && <div className="alert alert-danger" role="alert">
        {updateProfileState.error.message}
      </div>}
      <ProfileForm initial={profile}
        hideSignature
        onSubmit={async (profile) => {
          await updateProfile(profile)

          if (onProfileFilled) {
            await onProfileFilled()
          }
        }} />
    </div>
  </div>
}
