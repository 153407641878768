import { NotNull } from "../../types/util"

export function present<T>(obj: T): obj is Exclude<T, null | undefined | '' | false> {
  if (typeof obj === "string") {
    return /\S/.test(obj)
  }

  return !!obj
}

/**
 * Creates a function that checks if the given keys are present on the object.
 * Used to build type guards for Supabase rows.
 */
export function keysPresent<TKeys extends string>(
  ...keys: TKeys[]
)  {
  return <TObj extends { [k in TKeys]?: unknown }>(obj: TObj): obj is NotNull<TObj, TKeys> => {
    if (!present(obj)) {
      return false
    }

    for (const key of keys) {
      if (!present(obj[key])) {
        return false
      }
    }

    return true
  }
}
