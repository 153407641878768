import React, { useEffect, useMemo, useState } from 'react'
import { requireAuth } from '../wrappers/requireAuth'
import { useUser } from "../hooks/useUser"
import { useNavigate } from 'react-router'
import { FormFilledProfile, ProfileForm } from '../components/forms/profileForm'
import { useAsyncAction } from '../hooks/useAsyncAction'
import { useClient } from '../providers/supabase'
import { useLoadProfileFromServer } from '../hooks/useLoadProfileFromServer'
import { useAppDispatch, useAppSelector } from '../hooks/reduxToolkit'
import { onSyncMembership, setProfile } from '../reduxToolkit/membershipSlice'
import { merge } from "lodash";
import { updateExistingMembership } from '../../lib/queries/updateExistingMembership'
import * as bootstrap from "bootstrap"
import usePortal from 'react-useportal'
import { Link } from 'react-router-dom'

function EditProfile() {
  const user = useUser()
  if (!user) { throw new Error(`Not signed in!`) }

  const client = useClient()
  const profile = useAppSelector((s) => s.membership?.profile || {})
  const dispatch = useAppDispatch()

  useLoadProfileFromServer()

  const [{error}, updateProfile] = useAsyncAction(async (unsavedProfile: FormFilledProfile) => {
    // Ensure the profile has your ID
    const profile = {
      ...unsavedProfile,
      id: user.id,
      updated_at: new Date().toISOString()
    }
    
    const result = await client.from('profiles')
      .upsert(profile)
      .single()

    if (result.error) { throw result.error }

    dispatch(setProfile({
      profile: merge({}, profile, result.data)
    }))
  }, [client])

  const navigate = useNavigate()

  return <div className='row mt-4'>
    <div className='col-12'>
      <h1>Edit your profile</h1>

      <div className='row'>
        <div className='col-12'>
          <EditableMemberNumber />
        </div>
      </div>
      <hr />

      <ProfileForm initial={profile} onSubmit={async (profile) => {
        await updateProfile(profile)
    
        navigate('/')
      }} />

      {error && <div className='alert alert-danger' role='alert'>
        {error.message}
      </div>}
    </div>
  </div>
}

export default requireAuth(EditProfile)

function EditableMemberNumber() {
  const client = useClient()
  const { Portal } = usePortal()
  const dispatch = useAppDispatch()
  const user = useUser()

  const {membershipId, member_number} = useAppSelector((s) => s.membership)

  const [memberNumber, setMemberNumber] = useState(member_number || '')

  const uniqueId = useMemo<number>(() => Math.floor(Math.random() * 10_000), [])
  const domId = `modal-edit-member-number-${uniqueId}`
  const modalRef = React.useRef<HTMLDivElement>(null)

  const [{error}, updateMemberNumber, reset] = useAsyncAction(async (e: React.FormEvent) => {
    e.preventDefault()
    if (!(e.target as HTMLFormElement).checkValidity()) { return }

    const rows = await updateExistingMembership(client, {
      membershipId: membershipId,
      memberNumber: memberNumber
    })
    dispatch(onSyncMembership({
      userId: user.id,
      ...rows
    }))

    const modal = bootstrap.Modal.getInstance(document.getElementById(domId)!)
    modal?.hide()
  }, [client, memberNumber])

  useEffect(() => {
    const onHide = () => {
      setMemberNumber(member_number || '')
      reset()
    }
    const current = modalRef.current
    current?.addEventListener('hidden.bs.modal', onHide)
    return () => {
      current?.removeEventListener('hidden.bs.modal', onHide)
    }
  }, [member_number, reset])

  const isDuplicateKeyError = error?.message?.includes('violates unique constraint')

  return <>
    <label className="form-label">CHM Member Number</label>
    <div className='d-flex mb-2'>
      <input type='text' className='form-control' disabled value={member_number} />
      <button type="button" className={'btn btn-link'}
          data-bs-toggle="modal" data-bs-target={`#${domId}`}>
        Edit
      </button>
    </div>

    <Portal>
    <div ref={modalRef} className="modal modal-lg fade" id={domId} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Change My CHM Member Number</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form onSubmit={updateMemberNumber}>
              <label htmlFor='memberNumber' className='form-label'>
                Please enter your membership number for your CHM account.
              </label>
              <input type='text'
                className='form-control'
                value={memberNumber}
                required
                minLength={6}
                maxLength={6}
                onChange={(e) => setMemberNumber(e.target.value)} />

              <div className='mt-2'>
                <button type='submit' className='btn btn-primary'>
                  Update
                </button>
              </div>
            </form>

            {error && isDuplicateKeyError &&
              <div className='mt-2'>
                <p>
                  This membership number belongs to another account.<br/>
                  Please <Link to="/help">contact us for help</Link> if you would like to manage this
                  CHM membership number using this email address.
                </p>
              </div>}

            {error && !isDuplicateKeyError &&
              <div className='mt-2'>
                <div className='alert alert-danger' role='alert'>
                  Could not update your member number!
                </div>
                <p>
                  {error.message}
                </p>
                <p>
                  Please <Link to="/help">contact us for help</Link>
                </p>
              </div>}
          </div>
        </div>
      </div>
    </div>
    </Portal>
  </>
}
