import SignaturePad from "signature_pad"
import React, { useCallback, useEffect } from "react"

import './signatureCanvas.scss'

interface SignaturePadProps {
  data?: string | null | undefined
  onChange?:(signatureData: string | undefined) => void
}

export function SignatureCanvas({data, onChange}: SignaturePadProps) {

  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const signaturePadRef = React.useRef<SignaturePad>()
  const signatureData = React.useRef<string | undefined>(data || undefined)

  const clear = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    signaturePadRef.current?.clear()
    signatureData.current = undefined
    onChange?.(signatureData.current)
  }, [onChange])

  useEffect(() => {
    if (!canvasRef.current) { return }

    const canvas = canvasRef.current
    signaturePadRef.current = new SignaturePad(canvas, {
      minWidth: 2,
    })

    signaturePadRef.current.addEventListener('endStroke', () => {
      signatureData.current = signaturePadRef.current?.toDataURL()
      onChange?.(signatureData.current)
    })

    const resizeCanvas = () => {
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      const width = canvas.offsetWidth * ratio
      const height = canvas.offsetHeight * ratio;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d")?.scale(ratio, ratio);

      if (signaturePadRef.current) {
        signaturePadRef.current.clear(); // otherwise isEmpty() might return incorrect value
        if (signatureData.current) {
          signaturePadRef.current.fromDataURL(signatureData.current,
            { ratio: 1, width: canvas.offsetWidth, height: canvas.offsetHeight, xOffset: 0, yOffset: 0 })
        }
      }
    }
    window.addEventListener('resize', resizeCanvas)
    resizeCanvas()

    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.clear()
        signaturePadRef.current.off()
      }
      window.removeEventListener('resize', resizeCanvas)
    }
  }, [onChange])

  return <div className="signature-canvas">
    <div className="signature-canvas__actions">
      {signatureData.current && <a onClick={clear}><i className="material-icons">delete</i></a>}
    </div>
    <div className="ratio">
      <canvas ref={canvasRef} className=""></canvas>
    </div>
  </div>
}
