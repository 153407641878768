import { Patient } from "../dependentsSlice";
import { RootState } from "../store";

export function selectPatients(s: RootState): Patient[] {
  if (!s.dependents?.patients) {
    // TODO: get user from profile
    return []
  }

  return s.dependents.patients
}
