import { Link, useLocation } from "react-router-dom";

export function SignInTabNav() {
  const location = useLocation()

  return <ul className="nav nav-tabs">
    <li className="nav-item">
      <Link to="/signIn/email" className={`nav-link ${location.pathname === '/signIn/email' && 'active'}`}>
        Email Me a Code
      </Link>
    </li>
    <li className="nav-item">
    <Link to="/signIn/password" className={`nav-link ${location.pathname === '/signIn/password' && 'active'}`}>
        Enter Password
      </Link>
    </li>
  </ul>
}
