import { SupabaseClient } from '@supabase/supabase-js'
import { FileObject } from "@supabase/storage-js";
import {Database} from './generated/public'

export type AppSupabaseClient = SupabaseClient<Database,'public', Database['public']>

export type Tables = Database['public']['Tables']
export type AnyTable = keyof Tables
export type Data<tableName extends keyof Tables> = Tables[tableName]['Row']

export type ProfileRow = Tables['profiles']['Row']
export type ProfileInsert = Tables['profiles']['Insert']
export type ProfileUpdate = Tables['profiles']['Update']

export type ExpenseRow = Tables['expenses']['Row']
export type ExpenseInsert = Tables['expenses']['Insert']
export type ExpenseUpdate = Tables['expenses']['Update']

export type IncidentRow = Tables['incidents']['Row']
export type IncidentInsert = Tables['incidents']['Insert']
export type IncidentUpdate = Tables['incidents']['Update']

export type MembershipRow = Tables['memberships']['Row']

export type DependentRelationship = 'spouse' | 'child'

export type DependentRow = Omit<Tables['dependents']['Row'], 'relationship'> & {
  relationship?: DependentRelationship | null
}
export type DependentInsert = Omit<Tables['dependents']['Insert'], 'relationship'> & {
  relationship?: DependentRelationship | null
}

export type MembershipRoleType = 'admin' | 'user'
export type MembershipRoleRow = Tables['membership_roles']['Row'] & {
  role: MembershipRoleType
}

export type SettingsRow = Tables['settings']['Row']
export type SettingsUpdate = Tables['settings']['Update']

export type BlobRow = Tables['blobs']['Row']
export type BlobInsert = Tables['blobs']['Insert']

export type AttachmentRow = Tables['attachments']['Row']
export type AttachmentInsert = Tables['attachments']['Insert']

export type SubmissionRow = Omit<Tables['submissions']['Row'], 'data' | 'submission_type'> & {
  data: unknown
  submission_type: SubmissionType
}
export type SubmissionInsert = Omit<Tables['submissions']['Insert'], 'data' | 'submission_type'> & {
  data?: unknown
  submission_type?: SubmissionType
}
export type SubmissionUpdate = Omit<Tables['submissions']['Update'], 'data' | 'submission_type'> & {
  data?: unknown
  submission_type?: SubmissionType
}

export type SubmissionType =
  'CHM' |
  'CHM-addon' |
  'CHM-personal-responsibility' |
  'HRA'

export function raiseUnknownSubmissionType(type: never): never {
  throw new Error(`Unknown submission type ${type}`)
}

export type StorageItem = FileObject | DirObject

export interface DirObject {
  name: string
  id: null
  updated_at: null
  created_at: null
  last_accessed_at: null
  metadata: null
}

export function isDir(file: FileObject | DirObject): file is DirObject {
  return !file.id
}

export function isFile(file: FileObject | DirObject): file is FileObject {
  return !!file.id
}

export type Deleted<T> = T & { deleted_at: string }
export function isDeleted<T extends Record<string, any>>(record: T): record is Deleted<T> {
  return 'deleted_at' in record && !!record.deleted_at
}

export function assertDeleted<T extends { deleted_at?: string | null }>(record: T): asserts record is Deleted<T> {
  if (!isDeleted(record)) {
    throw new Error(`Record ${record} is not deleted`)
  }
}

export type NotDeleted<T extends { deleted_at?: string | null }> = Omit<T, 'deleted_at'>
export function isNotDeleted<T extends { deleted_at?: string | null }>(record: T): record is T & NotDeleted<T> {
  return !record.deleted_at
}
