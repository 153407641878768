import { useState } from 'react'
import { present } from '../../../lib/util/present'

interface EditableTextProps {
  value: string | undefined
  pattern?: string

  placeholder?: string
  prefix?: string

  editing?: boolean

  onChange: (value: string) => void
}

export function EditableText({value, pattern, prefix, placeholder, editing: controlledEditing, onChange}: EditableTextProps) {
  const [_editing, setEditing] = useState(false)

  const editing = controlledEditing ?? _editing

  if (editing) {
    return <input type="text"
      className="form-control"
      required
      defaultValue={[prefix, value].filter(present).join('')}
      placeholder={placeholder}
      pattern={pattern}
      autoFocus
      onBlur={(e) => {
        let newValue = e.target.value
        if (prefix && newValue.startsWith(prefix)) { newValue = newValue.slice(prefix.length) }

        if (newValue !== value && e.target.checkValidity()) {
          onChange(newValue)
        }
        setEditing(false)
      }}></input>
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a className='editable' onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditing(true) }}>
    {prefix}{value || <span className='editable-placeholder'>{placeholder}</span>}
  </a>
}
