
export function SplashScreen() {
  return <div className="row">
    <div className="col-12 col-lg-6 offset-lg-3">
      <img src={'/icon-512.png'} alt="logo" />

      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
}
