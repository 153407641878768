import * as bootstrap from "bootstrap"
import { useEffect, useMemo, useState } from "react"
import usePortal from "react-useportal"
import { EditExpense, EditExpenseProps } from "./editExpense"

export type EditExpenseButtonProps = EditExpenseProps & {
  className?: string
}

export function EditExpenseButton({
  onUpdated,
  expense,
  className,
  children,
  ...props
}: React.PropsWithChildren<EditExpenseButtonProps>) {
  const { Portal } = usePortal()

  const uniqueId = useMemo<number>(() => Math.floor(Math.random() * 10_000), [])
  const domId = `modal-edit-${expense.id}-${uniqueId}`
  const [shown, setShown] = useState(false)

  useEffect(() => {
    // setShown when the modal is shown
    const modalElem = document.getElementById(domId)
    const show = () => {
      setShown(true)
    }
    modalElem?.addEventListener('show.bs.modal', show)
    // clear shown when the modal is hidden
    const hide = () => {
      setShown(false)
    }
    modalElem?.addEventListener('hidden.bs.modal', hide)
    return () => {
      modalElem?.removeEventListener('show.bs.modal', show)
      modalElem?.removeEventListener('hidden.bs.modal', hide)
      if (modalElem) {
        const modal = bootstrap.Modal.getInstance(modalElem)
        modal?.hide()
      }
    }
  }, [domId])

  return <>
    <button type="button" className={`${className || 'btn btn-link'} edit-expense-button`}
        data-bs-toggle="modal" data-bs-target={`#${domId}`}>
      {children || 'Edit'}
    </button>

    <Portal>
    <div className="modal modal-lg fade" id={domId} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5" id="exampleModalLabel">Edit Expense</h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {shown && <EditExpense
              expense={expense}
              {...props}

              onUpdated={(...args) => {
                const modal = bootstrap.Modal.getInstance(document.getElementById(domId)!)
                modal?.hide()
                onUpdated && onUpdated(...args)
              }} />}
          </div>
        </div>
      </div>
    </div>
    </Portal>
  </>

}
