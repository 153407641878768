import { useEffect, useState } from 'react'
import { formatDateInTimeZone } from '../../../lib/formatDateInTimeZone'

interface EditableDateProps {
  value?: string | null
  format?: string
  placeholder?: string

  editing?: boolean

  onChange: (value: string) => void
}

export function EditableDate({value, placeholder, format, editing: controlledEditing, onChange}: EditableDateProps) {
  const [_editing, setEditing] = useState(false)

  const editing = controlledEditing ?? _editing

  if (editing) {
    return <input type="date"
      className="form-control"
      required
      defaultValue={value || ''}
      autoFocus
      onBlur={(e) => {
        if (e.target.value !== value && e.target.checkValidity()) {
          onChange(e.target.value)
        }
        setEditing(false)
      }}></input>
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a className='editable' onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditing(true) }}>
    {value ? formatDateInTimeZone(value, { format: format || 'MM/dd/yyyy' }) : <span className='editable-placeholder'>{placeholder}</span>}
  </a>
}
