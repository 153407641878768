import { useState } from "react";
import { useNavigate } from "react-router";
import { ForgotPasswordInitForm } from "../../components/forms/forgotPasswordInitForm";
import { useClient } from "../../providers/supabase";
import { useAsyncAction } from "../../hooks/useAsyncAction";
import { present } from "../../../lib/util/present";
import { Session } from "@supabase/supabase-js";

export function ForgotPassword({onLoginSuccess}: { onLoginSuccess?: (data: {session: Session}) => void}) {
  const [emailSent, setEmailSent] = useState('')

  return <div className='row mt-4'>
    <div className='col col-lg-6 offset-lg-3'>
      <h1>Reset My Password</h1>
      <p>Enter your email below and we'll send you a code to reset your password</p>
      {!emailSent &&
        <ForgotPasswordInitForm onEmailSent={(email) => setEmailSent(email)} />}
      {emailSent &&
        <ForgotPasswordVerifyForm email={emailSent} onLoginSuccess={(data) => { onLoginSuccess?.(data) }} />}
    </div>
  </div>
}

function ForgotPasswordVerifyForm({email, onLoginSuccess}: {email: string, onLoginSuccess: (data: {session: Session}) => void}) {
  const client = useClient()
  const navigate = useNavigate()


  const [token, setToken] = useState('')
  const canSubmit = present(token)

  const [submitState, submit] = useAsyncAction(async () => {
    const { data, error } = await client.auth.verifyOtp({
      email: email,
      token: token,
      type: 'recovery'
    })

    if (error) { throw error }
    const session = data.session
    if (!session) { throw new Error('Unable to verify your code. Please contact us for help.') }
    navigate(`/changePassword`)
    onLoginSuccess({session})
  }, [email, token, navigate, onLoginSuccess])

  return <form onSubmit={submit}>
    <div className="mb-3">
      <span className='form-label'>Email</span>
      <input name="email" type="text"
        className="form-control"
        required
        disabled={true}
        value={email}></input>
    </div>

    <p>Check your email for a password reset code and enter it here</p>
    <div className="mb-3">
    <input type="text" className='form-control'
          placeholder="6-digit code"
          value={token}
          onChange={(e) => {
            setToken(e.target.value)
          }} />
      </div>

    <div className="mb-3">
      {submitState.loading ?
        <button className='btn btn-primary disabled'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </button> :
        <button type='submit' className='btn btn-primary' disabled={!canSubmit}>Submit</button>}
    </div>

    <div className="mb-3">
      {submitState.error &&
        <p className="text-danger">
          {submitState.error.message}
        </p>}
    </div>
  </form>
}
