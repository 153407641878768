import { ChangePasswordForm } from "../../components/forms/changePasswordForm";
import { requireAuth, RequireAuthProvided } from "../../wrappers/requireAuth";

export interface OnboardingSetPasswordProps extends RequireAuthProvided {
  onSetPassword?: () => void
}

export function OnboardingSetPassword({user, onSetPassword}: OnboardingSetPasswordProps) {
  return <div className="row mt-4">
    <div className="col-12 col-xl-8 offset-xl-2">
      <h1>Set Password</h1>
      <p>
        Please choose a password for your account.
      </p>
      <ChangePasswordForm onChangePasswordSuccess={() => onSetPassword && onSetPassword()} />

      <p>
        <button className="btn btn-link text-small" onClick={() => onSetPassword && onSetPassword()}>
          I've already done this
        </button>
      </p>
    </div>
  </div>
}
