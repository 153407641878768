import type { ExpenseModel } from "../expensesSlice";
import type { IncidentModel } from "../incidentsSlice";
import { RootState } from "../store";
import { SubmissionModel } from "../submissionsSlice";

export type SubmissionById =
  SubmissionModel & {
    incident: IncidentModel,
    expenses?: ExpenseModel[]
  }

export function selectSubmissionById(id: string): (state: RootState) => SubmissionById | null {
  return (state) => {
    const submission = state.submissions.submissions.find((i) => i.id === id)
    if (!submission) {
      return null
    }
    const expenses = submission.expense_ids ? state.expenses.expenses.filter((e) => submission.expense_ids!.includes(e.id)) : undefined
    const incident = state.incidents.incidents.find((i) => i.id === submission.incident_id)
    if (!incident) {
      return null
    }

    return {
      ...submission,
      incident,
      expenses
    }
  }
}
