import type { Session } from '@supabase/supabase-js';
import { SignInWithEmail } from './signIn/signInWithEmail';
import { Routes, Route, Navigate } from 'react-router';
import { HashRouter, Link } from 'react-router-dom';
import Notifications from '../components/notifications';
import { Help } from '../routes/help';
import { NotFound } from './notFound';
import { ForgotPassword } from './signIn/forgotPassword';
import { SignInWithPassword } from './signIn/signInWithPassword';
import { VerifyOtp } from './signIn/verifyOtp';

import './signIn.scss';

export function SignIn({
  onLoginSuccess
}: {
  onLoginSuccess: (data: {session: Session}, navigateTo?: string) => any
}) {
  return (
    <div className="App container">
      <HashRouter>
        <SignInNav />
        <Routes> { /* your usual react-router v4/v5 routing */ }
          <Route path="/" element={<Navigate to="/signIn/email" />} />
          <Route path="/signIn" element={<Navigate to="/signIn/email" />} />
          <Route path="/signIn/email" element={<SignInWithEmail />} />
          <Route path="/signIn/password" element={<SignInWithPassword onLoginSuccess={onLoginSuccess} />} />
          <Route path="/verifyOtp" element={<VerifyOtp onLoginSuccess={onLoginSuccess} />} />
          <Route path="/forgotPassword" element={<ForgotPassword
            onLoginSuccess={(data) => {
              onLoginSuccess(data, '/changePassword')
            }} />} />

          {/* Reenable help after we figure out a captcha solution */}
          {/* <Route path="/help" element={<Help />} /> */}

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Notifications />
      </HashRouter>
    </div>
  );
}

function SignInNav() {
  return <nav className="navbar navbar-expand-lg top-nav">
  <div className="container-fluid">
    <div className='navbar-brand d-none d-md-block'>
      <h2>HealthShare</h2>
    </div>

    <div className='nav-item'>
      <Link to="/" className="nav-link">
        <i className='material-icons'>login</i>
        Sign In
      </Link>
    </div>

    <div className='nav-item mt-auto'>
      {/* <Link to="help" className='nav-link'>
        <i className="material-icons">help</i>
        <span className='d-none d-md-inline'>Help</span>
      </Link> */}
    </div>
  </div>
</nav>
}
