import { Link } from "react-router-dom";
import React from 'react'
import { SignInWithPasswordForm } from "../../components/forms/signInWithPasswordForm";
import { Session } from "@supabase/supabase-js";
import { SignInTabNav } from "./nav";

interface SignInWithPasswordProps {
  onLoginSuccess?: (data: {session: Session}) => void
}

export function SignInWithPassword({onLoginSuccess}: SignInWithPasswordProps) {

  return <div className='row justify-content-center mt-4 sign-in sign-in__with-password'>
    <div className='col-12 col-lg-6'>
      <h1>Sign In</h1>
      <SignInTabNav />
      <SignInWithPasswordForm onLoginSuccess={(s) => { onLoginSuccess?.(s) }} />
      <hr />
      <div className='d-flex'>
        <span>&nbsp;</span>

        <Link to='/forgotPassword' className='ms-auto'>Forgot password?</Link>
      </div>
    </div>
  </div>
}
