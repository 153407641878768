import { createSlice, SerializedError } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import pick from 'lodash/pick'
import { PURGE } from 'redux-persist';
import { SyncableTable, SyncableTables, SyncAction } from '../../lib/sync/action'
import { SyncResult } from '../../lib/sync/syncTableActions'
import { addExpense, deleteExpense, updateExpense, redactExpense } from './expensesSlice'
import { createAttachment, deleteAttachment, redactAttachment } from './attachmentsSlice'
import { addIncident, deleteIncident, updateIncident, redactIncident } from './incidentsSlice'
import { addSubmission, deleteSubmission, redactSubmission, updateSubmission } from './submissionsSlice'
import { isDeleted } from '../../types/supabase'
import { DeepNullable } from '../../types/util'
import { onSyncDownComplete, OnSyncDownCompletePayload } from './actions/onSyncDownComplete';

export type SyncSliceState = {
  pending: SyncAction<SyncableTable>[]
  errored: SyncResult['error']
  lastSync?: SyncResult | null
  lastSyncedAt?: number | null

  error?: SerializedError | null

  deletedLocally?: {
    [table in SyncableTable]?: Array<{ id: string, updated_at: string, deleted_at: string }>
  }
}

const initialState: SyncSliceState = {
  pending: [],
  errored: []
}

const SyncActions = [
  ['add', 'expenses', addExpense],
  ['update', 'expenses', updateExpense],
  ['delete', 'expenses', deleteExpense],
  ['add', 'incidents', addIncident],
  ['update', 'incidents', updateIncident],
  ['delete', 'incidents', deleteIncident],
  ['add', 'submissions', addSubmission],
  ['update', 'submissions', updateSubmission],
  ['delete', 'submissions', deleteSubmission],
  ['add', 'attachments', createAttachment],
  ['delete', 'attachments', deleteAttachment],
] as const

export const syncSlice = createSlice({
  name: 'supabase',
  initialState,
  reducers: {
    onSyncUpComplete: (state, action: PayloadAction<SyncResult>) => {
      const result = action.payload;
      const {pending, errored} = state;

      // Remove all the pending actions where we successfully completed the query to the server
      [...result.applied, ...result.rejected].forEach(action => {
        const i = pending.indexOf(action)
        pending.splice(i, 1)
      });

      // Add all the rejected actions to the errored list (they are also still pending)
      errored.push(...result.error)

      state.lastSync = result
      state.lastSyncedAt = Date.now()
    },
  },
  extraReducers: (builder) => {
    builder = SyncActions.reduce((b, [type, table, action]) =>
      b.addCase(action, (state, {payload}) => {
        // We could have multiple actions for the same record that need to be applied in order,
        // ex: addExpense, updateExpense, deleteExpense
        // If typescript complains about this, it means one of the above actions has an incorrect payload.
        // The payload must be a Row of a SyncableTable
        state.pending.push({
          type,
          table,
          record: payload
        } as SyncAction)

        if (isDeleted(payload)) {
          state.deletedLocally ||= {}
          const deletedLocally = state.deletedLocally[table] ||= []
          deletedLocally.push({
            id: payload.id,
            updated_at: payload.updated_at,
            deleted_at: payload.deleted_at
          })
        }
      }),
      builder)

    builder = builder.addCase(onSyncDownComplete, (state, action: PayloadAction<OnSyncDownCompletePayload>) => {
      const {pending, errored} = state;

      // When we load new data from the server, we want to remove any pending actions
      // that are no longer valid
      const payload = action.payload
      for (const table of SyncableTables) {
        const records = payload[table]
        if (!records) { continue }

        const {updated, deleted} = records
        updated?.forEach((record) => {
          removeIfObsolete(pending, record)
          removeIfObsolete(errored, record)
        })
        deleted?.forEach((record) => {
          removeIfObsolete(pending, record)
          removeIfObsolete(errored, record)

          const deletedLocally = state.deletedLocally?.[table]
          if (deletedLocally) {
            const i = deletedLocally?.findIndex((r) => r.id === record.id)
            if (i !== undefined && i >= 0) {
              deletedLocally.splice(i, 1)
            }
          }
        })
      }

      if (payload.syncActions) {
        state.pending.push(...payload.syncActions)
      }

    })

    builder = builder.addCase(PURGE, (state) => {
      return initialState
    })

    return builder
  }
})

export type SyncSliceAction = ReturnType<typeof syncSlice.actions[keyof typeof syncSlice.actions]> |
  ReturnType<typeof onSyncDownComplete>

export function isSyncSliceAction(action: any): action is SyncSliceAction {
  return action.type?.startsWith(syncSlice.name)
}

export const { onSyncUpComplete } = syncSlice.actions

export default syncSlice.reducer

/**
 * Remove a pending or errored action if it is made obsolete by a newer version of the record
 */
function removeIfObsolete<T extends SyncableTable>(pending: SyncAction<T>[], updatedRecord: SyncAction<T>['record']): void {
  const toRemove = pending.filter((t) => t.record.id === updatedRecord.id && t.record.updated_at <= updatedRecord.updated_at)
  toRemove.forEach((t) => {
    const i = pending.indexOf(t)
    // Remove the pending action
    pending.splice(i, 1)
  })
}

export function redactSyncSlice(state: SyncSliceState): DeepNullable<SyncSliceState> {
  return {
    ...pick(state, 'lastSyncedAt', 'error'),
    pending: state.pending.map(redactSyncAction),
    errored: state.errored.map(redactSyncAction),
    lastSync: state.lastSync ? {
      ...pick(state.lastSync, 'updated_at'),
      applied: state.lastSync.applied.map(redactSyncAction),
      rejected: state.lastSync.rejected.map(redactSyncAction),
      error: state.lastSync.error.map(redactSyncAction),
      snapshot: null
    } : null
  } as DeepNullable<SyncSliceState>
}

export function redactSyncSliceAction(action: SyncSliceAction) {

  switch(action.type) {
    case onSyncDownComplete.type:
      return {
        type: action.type,
        payload: {
          expenses: action.payload.expenses && {
            updated: action.payload.expenses.updated?.map(redactExpense),
            deleted: action.payload.expenses.deleted?.map(redactExpense)
          },
          incidents: action.payload.incidents && {
            updated: action.payload.incidents.updated?.map(redactIncident),
            deleted: action.payload.incidents.deleted?.map(redactIncident)
          },
          submissions: action.payload.submissions && {
            updated: action.payload.submissions.updated?.map(redactSubmission),
            deleted: action.payload.submissions.deleted?.map(redactSubmission)
          },
          attachments: action.payload.attachments && {
            updated: action.payload.attachments.updated?.map(redactAttachment),
            deleted: action.payload.attachments.deleted?.map(redactAttachment)
          },
        }
      }
    case onSyncUpComplete.type:
      return {
        type: action.type,
        payload: {
          ...pick(action.payload, 'updated_at'),
          applied: action.payload.applied.map(redactSyncAction),
          rejected: action.payload.rejected.map(redactSyncAction),
          error: action.payload.error.map(redactSyncAction),
        }
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

function redactSyncAction<T extends SyncAction>(action: T): T {
  return {
    ...pick(action, 'type', 'table'),
    record: pick(action.record, 'id', 'updated_at')
  } as T
}
