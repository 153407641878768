import React from "react";
import { Link } from "react-router-dom";

export function NotFound({children}: React.PropsWithChildren) {

  return <div className="row">
    <div className="col">
      <h1>404 Not Found</h1>
      {children ||
        <p>We couldn't find what you're looking for.</p>}

      <Link to="/">Home</Link>
    </div>
  </div>
}