import { useAppSelector, useAppDispatch } from "../../../hooks/reduxToolkit";
import * as Sentry from "@sentry/react";
import {v4 as uuid4} from 'uuid'
import { useAsyncAction } from "../../../hooks/useAsyncAction";
import { usePendingExpenses } from "../../../hooks/usePendingExpenses";
import { generateAddOn2023 } from "../../../../lib/documents/2023";
import { createBlob } from "../../../../lib/supabase/createBlob";
import { useClient } from "../../../providers/supabase";
import { createAttachment } from "../../../reduxToolkit/attachmentsSlice";
import { SubmissionById } from "../../../reduxToolkit/selectors/submissionById";
import { RequireSubmissionProvided, requireSubmission } from "../../../wrappers/requireSubmission";
import { completeSubmission } from "../../../reduxToolkit/actions/completeSubmission";
import { formatDateInTimeZone } from "../../../../lib/formatDateInTimeZone";
import { useNavigate } from "react-router";
import { useUser } from "../../../hooks/useUser";
import { useLoadBlobs } from "../../../hooks/useLoadBlobs";
import { useEffect, useState } from "react";
import { BlobFileBrowser } from "../../../components/blobFileBrowser";
import { Checklist } from "../../../components/checklist";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { SubmissionModel, isSubmitted } from "../../../reduxToolkit/submissionsSlice";
import { assert } from "../../../../lib/util/assert";
import { useShareBlobs } from "../../../hooks/useShareBlobs";
import { isMobile } from "../../../../lib/util/isMobile";
import { useFeature } from "../../../providers/featureFlags";
import { slugify } from "../../../../lib/util/slugify";
import { BlobRow } from "../../../../types/supabase";
import { Tooltip } from "../../../components/tooltip";
import { CopyableSpan } from "../../../components/copyableSpan";
import { IncidentModel } from "../../../reduxToolkit/incidentsSlice";

import './submitCHMAddOn.scss'
import { EmailMethodTab } from "./submitToCHM/emailMethodTab";

interface SubmitCHMAddOnProps extends RequireSubmissionProvided {
}

export function SubmitCHMAddOn({ submission }: SubmitCHMAddOnProps) {
  const dispatch = useAppDispatch()
  const user = useUser()
  const navigate = useNavigate()

  const pendingExpenses = usePendingExpenses(submission)
  const allAttachmentRecords = [submission.id, ...(submission.expense_ids || []), ...pendingExpenses.map((e) => e.id)]
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => allAttachmentRecords.includes(a.record_id)))

  const [blobs, loadBlobsState] = useLoadBlobs(attachments.map((a) => a.blob_key))

  const expectedFileName = `MedicalBillWorksheet_addon_${formatDateInTimeZone(submission.created_at, { format: 'yyyy-MM-dd' })}.pdf`
  const [generateDocumentState, generateDocument] = useGenerateAddOn(submission, expectedFileName)
  const documentIsGenerated = blobs?.find((b) => b.file_name === expectedFileName)

  const [nextStepsCompleted, setNextStepsCompleted] = useState(false)

  // Redirect if already submitted
  useEffect(() => {
    if (isSubmitted(submission)) {
      navigate(`/incidents/${submission.incident_id}`)
    }
  }, [submission, navigate])

   // After we load the blobs, if we don't have the add on doc yet, generate and upload it.
   useEffect(() => {
    if (loadBlobsState.loading) { return }

    if (documentIsGenerated) {
      // We already have all the needed documents
      return
    }

    generateDocument()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadBlobsState.loading])

  const finishSubmission = () => {
    dispatch(completeSubmission({
      submission: submission,
      blobs: blobs || [],
      pendingExpenses: pendingExpenses,
      submittedByUserId: user?.id || '',
    })).then(() => {
      navigate(`/incidents/${submission.incident_id}`)
    }).catch((ex) => {
      Sentry.captureException(ex)
      toast.error('Unable to complete submission. Please try again.')
    })
  }

  if (loadBlobsState.loading || generateDocumentState.loading) {
    return <div className="row submit-to-chm">
      <div className="col-12">
        <h2>Submit CHM Add-On</h2>

        <>
          <p>Generating your PDFs...</p>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      </div>
    </div>
  }
  if (loadBlobsState.error) { throw loadBlobsState.error }
  if (generateDocumentState.error) { throw generateDocumentState.error }

  if (loadBlobsState.loading) {
    return <div className="row submit-to-chm">
      <div className="col-12">
        <h2>Submit to CHM</h2>

        <>
          <p>Loading your PDFs...</p>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </>
      </div>
    </div>
  }
  assert(blobs, 'blobs should be loaded at this point')

  const incident = submission.incident
  const zipFileName = `${formatDateInTimeZone(submission.incident.start_date, { format: 'yyyy-MM-dd' })}_${slugify(submission.incident.description || '')}_addon`

  return <div className="row submit-to-chm">
    <div className="col-12">
      <h2>Submit CHM Add-On</h2>

      <p>
        We've filled out your add-on documents! You can download them below.<br />
        If you need documents from previous submissions, <Link to={`/files/incidents/${incident.start_date} ${incident.description}/submissions`} target="_blank">you can find them here</Link>
      </p>
    </div>
    <div className="col-12 col-lg-6 submit-to-chm__file-browser">
      <BlobFileBrowser
        settings={{
          hideNavigation: true,
          initialView: 'list',
        }}
        rootFolderName={zipFileName}
        files={{
          '/': blobs
          }} />
    </div>
    <div className="col-12 col-lg-6 submit-to-chm__next-steps">
      <ChmAddonNextSteps incident={incident} setNextStepsCompleted={setNextStepsCompleted} blobs={blobs} zipFileName={zipFileName} />
    </div>

    <div className="col-12">
      <p>
        <button onClick={finishSubmission}
            className={`btn w-100 btn-primary`}>
          I've submitted my add-on documents
        </button>
      </p>
    </div>
  </div>
}

export default requireSubmission(SubmitCHMAddOn)

interface CHMAddonNextStepsProps {
  incident: IncidentModel,
  blobs: BlobRow[],
  zipFileName: string
  setNextStepsCompleted: (completed: boolean) => void
}

function ChmAddonNextSteps({ incident, blobs, zipFileName, setNextStepsCompleted }: CHMAddonNextStepsProps) {

  const emailTabShown = useFeature('share_chm_submission') && isMobile()
  const mobile = isMobile()
  const canShare = typeof navigator.share === 'function'
  const emailTabShownFirst = emailTabShown && mobile && canShare
  
  return <>
    <ul className="nav nav-tabs" id="myTab" role="tablist" style={!emailTabShown ? { display: 'none' } : {}}>
      <li className="nav-item" role="presentation">
        <button id="chm-portal-tab"
          className={`nav-link ${!emailTabShownFirst && 'active'}`}
          data-bs-toggle="tab" data-bs-target="#chm-portal-tab-pane" type="button" role="tab"
          aria-controls="home-tab-pane" aria-selected="true">
            Browser Method
          </button>
      </li>
      {emailTabShown && <li className="nav-item" role="presentation">
        <button id="email-tab"
          className={`nav-link ${emailTabShownFirst && 'active'}`}
          data-bs-toggle={'tab'} data-bs-target="#email-tab-pane" type="button" role="tab"
          aria-controls="profile-tab-pane" aria-selected="false">
            Email Method
        </button>
      </li>}
    </ul>
    <div className="tab-content" id="myTabContent">
      <div className={`tab-pane ${!emailTabShownFirst && 'show active'}`} id="chm-portal-tab-pane" role="tabpanel" aria-labelledby="chm-portal-tab" tabIndex={0}>
        <h4>Next Steps:</h4>
        <p>
          <Checklist
            onChange={({completed}) => setNextStepsCompleted(completed)}
            items={[
              <span>
                Download the add-on worksheet to your computer.
              </span>,
              <span>
                Log In to the Membership portal, find the "Sharing" drop-down, and click "Submit Medical Bills" <br/>
                <a href="https://portal.chministries.org/Needs/SubmitNeed" target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
                  https://portal.chministries.org/Needs/SubmitNeed</a>
              </span>,
              <span>
                Click on "Upload Printed PDFs"
              </span>,
              <span>
                Find "Medical Bill Worksheet", click "Browse" and browse to the add-on worksheet you downloaded.
              </span>,
              <span>
                For each itemized receipt in your files, click "Itemized Bill" and browse to the correct file.
              </span>,
              <span>
                Make sure you check the "This is an add-on bill" checkbox!
              </span>,
            ]} />
        </p>
      </div>
      {emailTabShown && <div className={`tab-pane ${emailTabShownFirst && 'show active'}`} id="email-tab-pane" role="tabpanel" aria-labelledby="email-tab" tabIndex={1}>
        <EmailMethodTab incident={incident} blobs={blobs} zipFileName={zipFileName} />
      </div>}
    </div>
  </>
}


function useGenerateAddOn(
  submission: SubmissionById,
  fileName?: string
) {
  const client = useClient()
  const membership = useAppSelector((s) => s.membership)
  const pendingExpenses = usePendingExpenses(submission)
  const dispatch = useAppDispatch()

  return useAsyncAction(async () => {
    const user = (await client.auth.getUser()).data.user
    if (!user) { throw new Error('Not signed in') }
    if (!membership.profile) { throw new Error('Profile not set') }

    const document = await generateAddOn2023(
      {
        membership,
        profile: membership.profile,
        incident: submission.incident,
        expenses: pendingExpenses
      },
      fileName
    )

    const blob = await createBlob(document,
        {
          membership_id: membership.membershipId,
        },
        {
          client
        })

    const attachment =
      dispatch(createAttachment({
        id: uuid4(),
        record_id: submission.id,
        table_name: 'submissions',
        blob_key: blob.key,
        membership_id: blob.membership_id,
        updated_at: blob.updated_at,
        created_at: blob.created_at,
      }))

    return {
      document,
      blob,
      attachment
    }
  }, [submission.id])
}
