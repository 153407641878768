import { ProfileModel } from "../../app/reduxToolkit/membershipSlice"
import { DependentRow } from "../../types/supabase"
import { formatDateInTimeZone } from "../formatDateInTimeZone"
import {fillPdf} from './fillPdf'
import { writeSignature } from "./writeSignature"

const formFields = [
  'Name',
  'Address',
  'Address #2',
  'Phone #',
  'Initials',
  'signature',
  'Printed Name',
  'Authorized Rep Signature',
  'Text13',
  'Text14',
  'Month',
  'Day',
  'Year',
  'CHM1',
  'CHM2',
  'CHM3',
  'CHM4',
  'CHM6',
  'SS1',
  'SS2',
  'SS3',
  'SS4',
  'SS5',
  'SS6',
  'SS7',
  'SS8',
  'Text16',
  'Text15',
  'SS9',
  'CHM5'
] as const

type MedicalReleaseHipaaFormField = typeof formFields[number]

export async function fillMedicalReleaseHipaaForm(
  pdfBytes: ArrayBuffer,
  {membership, profile, dependent}: {
    membership: { member_number?: string | null }
    profile: ProfileModel | null | undefined,
    dependent: DependentRow | null
  }
): Promise<ArrayBuffer> {

  const fields: { [k in MedicalReleaseHipaaFormField]?: string | null | undefined } = {
    // name of patient
    Name: dependent?.full_name || profile?.full_name,
    'Printed Name': dependent?.full_name || profile?.full_name,
  }

  if (dependent) {
    // The user is filling this out on behalf of a dependent

    // This is actually the rep's relationship to parent
    fields['Authorized Rep Signature'] = dependent.relationship
    fields['Text13'] = profile?.full_name
    const dob = new Date(Date.parse(formatDateInTimeZone(dependent.date_of_birth)))
    fields['Day'] = dob.getDate().toString()
    fields['Month'] = (dob.getMonth() + 1).toString()
    fields['Year'] = dob.getFullYear().toString().slice(2)
  } else {
    if (profile?.date_of_birth) {
      const dob = new Date(Date.parse(formatDateInTimeZone(profile.date_of_birth)))
      fields['Day'] = dob.getDate().toString()
      fields['Month'] = (dob.getMonth() + 1).toString()
      fields['Year'] = dob.getFullYear().toString().slice(2)
    }
  }

  if (profile?.full_name) {
    const [first, last] = profile.full_name.split(/\s+/)
    fields['Initials'] = `${first.charAt(0)}${last.charAt(0)}`
  }

  if (membership?.member_number) {
    for (let i = 0; i < 6; i++) {
      fields[`CHM${i + 1}` as MedicalReleaseHipaaFormField] = membership.member_number.charAt(i)
    }
  }

  if (profile?.phone) {
    fields['Phone #'] = profile.phone
  }

  if (profile?.address) {
    const [address, ...address2] = profile.address.split(/\n/)
    fields['Address'] = address.trim()
    fields['Address #2'] = address2.join(' ').trim()
  }

  // date
  const now = new Date()
  fields['Text14'] = (now.getMonth() + 1).toString()
  fields['Text15'] = now.getDate().toString()
  fields['Text16'] = now.getFullYear().toString().slice(2)

  pdfBytes = await fillPdf(pdfBytes, fields)

  if (profile?.signature_data) {
    pdfBytes = await writeSignature(pdfBytes, {
      signature: profile.signature_data,
      formField: 'signature'
    })
  }

  return pdfBytes
}
