import { useRef, useState } from "react"
import { present } from "../../../lib/util/present"
import { Patient } from "../../reduxToolkit/dependentsSlice"
import { IncidentModel } from "../../reduxToolkit/incidentsSlice"
import { IncidentSelect } from "./incidentSelect"

export type IncidentInputGroupValue = IncidentModel | {
  id?: undefined,
  description?: string
  is_addon_incident?: boolean
}

export interface IncidentInputGroupProps {
  /**
   * If true, the user can create a new incident.
   */
  allowNewIncident?: boolean
  /**
   * If provided, the input will be disabled and the incident with this ID will be used.
   */
  requiredIncidentId?: string
  
  /** Sets the "required" prop on the form input */
  required?: boolean

  /**
   * Limits the incidents that can be selected to those that are associated with this patient.
   */
  selectedPatient?: Patient | null | undefined | false

  /**
   * The currently selected incident.
   */
  value: IncidentInputGroupValue | null | undefined

  /**
   * Called when the user changes the incident.
   */
  onChange: (value: IncidentInputGroupValue | null | undefined) => void
}

export function IncidentInputGroup({
  allowNewIncident: _allowNewIncident,
  requiredIncidentId,
  selectedPatient,
  required,

  value,
  onChange
}: IncidentInputGroupProps) {
  const inputGroupRef = useRef<HTMLDivElement>(null)

  // We can only choose an incident if we're not explicitly requiring one
  const allowNewIncident = _allowNewIncident && !present(requiredIncidentId)

  const newIncident = value && !present(value.id)

  const [isAddOn, setIsAddOn] = useState(false)
  const [isUntrackedAddOn, setIsUntrackedAddOn] = useState(false)

  const incidentSelectValue = requiredIncidentId ? requiredIncidentId : newIncident ? '!new' : value?.id || ''
  return <>
    <div className={`input-group`} ref={inputGroupRef}>
      <span className='input-group-text'>Incident</span>
      <IncidentSelect
        className='form-select'
        required={required}
        // If we're adding an expense to a specific incident, then we can't change it
        disabled={present(requiredIncidentId)}
        value={incidentSelectValue}

        patient={selectedPatient}

        denyNewIncidents={!allowNewIncident}
        denyOldIncidents={false}

        onSelectNewIncident={() => {
          if (allowNewIncident) {
            onChange({description: ''})
          }
        }}
        onSelect={(value) => {
          onChange(value)
        }} />
    </div>

    <div className={`input-group has-validation ${!newIncident && 'd-none'}`}>
      <label className='input-group-text d-none d-md-flex'>New Incident Name</label>
      <label className='input-group-text d-flex d-md-none'>Name</label>
      <input type="text" className='form-control incident-input-group__description'
        required={!!newIncident}
        disabled={isAddOn && !isUntrackedAddOn}

        placeholder={isAddOn ? "" : 'yearly checkup / er visit / etc'}
        value={isAddOn && !isUntrackedAddOn ? '' : value?.description || ''}
        onChange={(e) =>  onChange({...value, description: e.target.value})} />
    </div>

    <div className={`input-group has-validation ${!newIncident && 'd-none'}`}>
      <div className="form-check">
        <input className="form-check-input" type="checkbox"
          checked={isAddOn}
          onChange={() => {
            setIsAddOn(!isAddOn)
          }}/>
        <label className="form-check-label">
          This is an add-on to an existing incident
        </label>
      </div>
    </div>

    {isAddOn && <div className={`input-group has-validation ${!isAddOn && 'd-none'}`}>
      <label className='input-group-text d-none d-md-flex'>Add on to which incident?</label>
      <label className='input-group-text d-flex d-md-none'>Which Incident?</label>
        <IncidentSelect
          className='form-select'
          required={required}

          disabled={present(requiredIncidentId)}

          denyNewIncidents={true}
          denyOldIncidents={true}
          hideUnsubmittedIncidents={true}
          showSubmittedIncidents={true}
          blankOption={false}

          additionalOptions={[
            {
              key: '!untracked',
              label: "I didn't track it in this app"
            }
          ]}

          patient={selectedPatient}

          value={isUntrackedAddOn ? '!untracked' : incidentSelectValue}
          onSelectAdditionalOption={(key) => {
            if (key === '!untracked') {
              setIsUntrackedAddOn(true)
              onChange({...value, is_addon_incident: true})
            }
          }}
          onSelect={(value) => {
            if (present(value)) {
              onChange(value)
              setIsAddOn(false)
              setIsUntrackedAddOn(false)
            }
          }} />
    </div>}
  </>
}
