import React, { useEffect, useRef } from "react"
import * as  bootstrap from 'bootstrap'
import { present } from "../../lib/util/present"

export function Tooltip({children, placement, tooltip}: React.PropsWithChildren<{tooltip: string | null | false, placement?: 'top' | 'left' | 'right'}>) {
  const ref = React.useRef<HTMLSpanElement>(null)
  const tooltipInstance = useRef<bootstrap.Tooltip | null>(null)

  useEffect(() => {
    if (!present(tooltip)) { return }
    if (!ref.current) { return }

    tooltipInstance.current = new bootstrap.Tooltip(ref.current, {
    })
    const handle = () => { tooltipInstance.current?.hide() }
    document.addEventListener('click', handle)

    return () => {
      tooltipInstance.current?.dispose()
      document.removeEventListener('click', handle)
    }
  }, [tooltip])
  
  useEffect(() => {
    if (!tooltipInstance.current) { return }
    if (!present(tooltip)) { return }
    
    // Update the tooltip text
    ref.current?.setAttribute('data-bs-original-title', tooltip)
  }, [tooltip])
  
  if (!present(tooltip)) { return <>{children}</> }

  return <span ref={ref}
    data-bs-toggle='tooltip'
    data-bs-placement={placement || 'top'}
    title={tooltip}
      onClick={(e) => {
        e.preventDefault();
        tooltipInstance.current?.toggle()
      }}>
      {children || <i className="material-icons">help</i>}
    </span>
}
