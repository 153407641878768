import {HashRouter, Route, Routes} from 'react-router-dom'

import { Homepage } from './app/routes/homepage';
import { TopNav } from './app/components/topNav'
import { Expenses } from './app/routes/expenses';
import EditProfile from './app/routes/editProfile';
import IncidentsShow from './app/routes/incidents/show';
import ChangePassword from './app/routes/changePassword';
import { NotFound } from './app/screens/notFound';
import { Files } from './app/routes/files';
import EditDependents from './app/routes/editDependents';
import Notifications from './app/components/notifications';
import { Help } from './app/routes/help';
import IncidentsSubmit from './app/routes/incidents/submit';
import SubmitHome from './app/routes/incidents/submit/home';
import SubmitShow from './app/routes/incidents/submit/show';
import { BulkAddExpense } from './app/routes/bulkAddExpense';
import BulkAddExpenseToIncident from './app/routes/incidents/bulkAddExpenseToIncident';
import { Todos } from './app/routes/todos';
import SubmitPersonalResponsibility from './app/routes/incidents/submit/submitPersonalResponsibility';
import { DeleteAccount } from './app/routes/deleteAccount';
import EditSettings from './app/routes/editSettings';

function App() {
  return (
    <div className="App container">
      <HashRouter>
        <TopNav />
        <Routes> { /* your usual react-router v4/v5 routing */ }
          <Route path="/" element={<Homepage />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/profile" element={<EditProfile />} />
          <Route path="/settings" element={<EditSettings />} />
          <Route path="/dependents" element={<EditDependents />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/bulkAddExpense" element={<BulkAddExpense />} />
          <Route path="/incidents/:id" element={<IncidentsShow />} />
          <Route path="/incidents/:incidentId/submit" element={<IncidentsSubmit />} >
            <Route index element={<SubmitHome />} />
            <Route path="/incidents/:incidentId/submit/:id" element={<SubmitShow />} />
            <Route path="/incidents/:incidentId/submit/:submissionId/personal-responsibility" element={<SubmitPersonalResponsibility />} />
          </Route>
          <Route path="/incidents/:incidentId/bulkAddExpense" element={<BulkAddExpenseToIncident />} />
          <Route path="/todos" element={<Todos />} />
          <Route path="/files/*" element={<Files />} />
          <Route path="/help" element={<Help />} />
          <Route path="/deleteAccount" element={<DeleteAccount />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Notifications />
        <div className='mt-4' />
      </HashRouter>
    </div>
  );
}

export default App;
