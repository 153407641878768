import * as bootstrap from "bootstrap"
import { useEffect, useState } from "react"
import { QuickAddExpense, QuickAddExpenseProps } from "./quickAddExpense"
import usePortal from "react-useportal"

export function NewExpenseFormButton({
  onInserted,
  ...props
}: QuickAddExpenseProps) {

  const { Portal } = usePortal()
  const [uniqueId, setUniqueId] = useState<number>(1)
  
  useEffect(() => {
    const modalElem = document.getElementById('newExpenseFormModal')
    return () => {
      // Hide the modal when we navigate away
      if (modalElem) {
        const modal = bootstrap.Modal.getInstance(modalElem)
        modal?.hide()
      }
    }
  })

  return <>
    <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#newExpenseFormModal">
      + Add Expense
    </button>

    <Portal>
    <div className="modal modal-lg fade" id="newExpenseFormModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title fs-5" id="exampleModalLabel">Add Expense</h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <QuickAddExpense key={uniqueId} {...props}
              onInserted={(...args) => {
              const modal = bootstrap.Modal.getInstance(document.getElementById('newExpenseFormModal')!)
              modal?.hide()
              setUniqueId(uniqueId + 1)
              onInserted && onInserted(...args)
            }} />
          </div>
        </div>
      </div>
    </div>
    </Portal>
  </>

}
